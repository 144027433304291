
.FeedbackPopup {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  animation: appear 1s ease;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.FeedbackPopup-Content {
  position: relative;
  width: 100%;
  max-width: 400px;
  background-color: white;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;

}

.FeedbackPopup h1 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
  text-align: center;
}

.FeedbackPopup p {
  font-size: 16px;
  margin-bottom: 16px;
  text-align: center;
}

.FeedbackPopup-Stars {
  display: flex;
  justify-content: center;
  align-items: center;
}

.FeedbackPopup-Star {
  all: unset;
  width: 32px;
  height: 32px;
  margin: 0 8px;
  background-image: url(https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/grade/fill1/48px.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5;
  transition: all 0.3s ease;
  cursor: pointer;
}

.FeedbackPopup-Textarea {
  display: block;
  width: 80%;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 8px;
  margin-bottom: 16px;
  margin-top: 32px;
}

.Dismiss-Button {
  all: unset;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 16px;
  right: 16px;
  color: grey;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  text-align: center;
}

.Dismiss-Button:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}