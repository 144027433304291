.GoBack {
  position: absolute;
  top: 0;
  left: 0;
  padding: 16px;
  font-size: 16px;
  font-weight: 700;
  color: white;
  background: linear-gradient(145deg, #ff0000, #8b00ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease;
}