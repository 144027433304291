.Screen {
  flex: 1;
  display: flex;
}

.Mix {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Result {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Clear-Button {
  all: unset;
  border-radius: 4px;
  width: 200px;
  height: 36px;
  margin-bottom: 16px;
  color: black;
  font-weight: 700;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  opacity: 0.9;
  transition: all 0.3s ease;
}

.Clear-Button:disabled {
  opacity: 0.3;
  pointer-events: none;
}

.Clear-Button:hover {
  cursor: pointer;
  opacity: 1;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
}

/* Flip the app on small screens */
@media (max-width: 600px) {
  .Screen {
    flex-direction: column;
  }
}