.ColorButton {
  all: unset; 
  border-radius: 4px;
  width: 200px;
  height: 56px;
  margin-bottom: 16px;
  color: white;
  font-weight: 700;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  opacity: 0.9;
  transition: all 0.3s ease;
}

.ColorButton:disabled {
  opacity: 0.5;
}

.ColorButton:hover {
  cursor: pointer;
  opacity: 1;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
}