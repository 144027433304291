.Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

a {
  /* Use a gradient as color for the text */
  background: linear-gradient(145deg, #ff0000, #8b00ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 18px;
  transition: all .3s ease;

  margin: 24px;
}

/* When hovering over links, do some crazy transitions */
a:hover {
  background: linear-gradient(145deg, #8b00ff, #ff0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
}
